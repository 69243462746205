@font-face {
  font-family: "alatsi";
  src: local("alatsi"),
    url(./fonts/alatsi/Alatsi-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "fonti";
  src: local("fonti"),
    url("./fonts/fonti/Niramit-Medium.ttf") format("truetype");
}

body {
  margin: 0px;
}

.title {
  font-family: "alatsi";
}

.title h1 {
  text-align: right;
}

.title h2 {
  text-align: right;
}

.contact-us {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* justify-content: center; */
  font-family: "fonti";
  font-weight: bolder;
  /* text-align: left; */
}

.contact-us a {
  text-decoration: none;
  color: black;
}

.header {
  display: flex;
  flex-direction: row;
  box-shadow: 1px 1px 1px grey;
  margin: 0px;
  position: fixed;
  width: 100%;
  padding: 0.5rem 2rem;
  z-index: 5;
  background-color: white;
}

.body {
  position: relative;
  top: 7.7rem;
  font-family: "fonti";
}

.banner {
  background-image: url(./data/banner.jpg);
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0rem;
}

.info h2 {
  color: blue;
  margin-bottom: 0px;
}

.form-container {
  background-color: rgb(229, 231, 232);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 35% 1fr;
  grid-gap: 1rem;
  padding: 4rem 0rem 2rem 2rem;
}

.form-heading {
  color: blue;
}

.form-body {
  background-color: white;
  padding: 0rem 3rem 4rem 2rem;
}

.form {
  display: flex;
  flex-direction: column;
}

input {
  border: none;
  background-color: rgb(229, 231, 232);
  margin-bottom: 1rem;
  height: 2rem;
  padding-left: 0.5rem;
}

textarea {
  border: none;
  background-color: rgb(229, 231, 232);
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

input:focus {
  border: none;
  outline: none;
}

textarea:focus {
  border: none;
  outline: none;
}

button {
  width: 6rem;
  height: 2.5rem;
  text-align: center;
  border: none;
  background-color: blue;
  color: white;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.img {
  width: 90%;
  height: 60%;
  background-image: url("./data/img.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.f1 {
  text-align: center;
}

.f2 {
  display: flex;
}

.f21 {
  width: 86%;
  padding-left: 1rem;
}

.f22 {
  text-align: right;
}

.footer {
  font-size: small;
  color: grey;
}

.success {
  font-size: large;
  background-color: green;
  color: white;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.success p {
  width: 95%;
}

.footer a {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .title h1 {
    padding-left: 0.5rem;
    text-align: left;
  }

  .title h2 {
    text-align: left;
    padding-left: 0.5rem;
  }

  .contact-us {
    font-family: "fonti";
    font-weight: bolder;
  }

  .contact-us a {
    text-decoration: none;
    color: black;
  }

  .logo {
    width: 100%;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 1px 1px 1px grey;
    margin: 0px;
    position: fixed;
    width: 100%;
    padding: 0rem 0rem;
    z-index: 5;
    background-color: white;
  }

  .body {
    position: relative;
    top: 9rem;
    font-family: "fonti";
  }

  .banner {
    background-image: url(./data/banner.jpg);
    height: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
  }

  .form-container {
    background-color: rgb(229, 231, 232);
    display: grid;
    grid-template-rows: auto 20%;
    grid-template-columns: auto;
    grid-gap: 1rem;
    padding: 3rem 0.5rem;
    height: 63rem;
  }

  .form-body {
    background-color: white;
    padding: 1rem;
  }

  .img {
    width: 100%;
    height: 100%;
    background-image: url("./data/img.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .info h2 {
    color: blue;
    margin-bottom: 0px;
  }

  .f1 {
    text-align: center;
  }

  .f2 {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .f21 {
    width: 100%;
    padding-left: 0rem;
  }

  .f22 {
    text-align: center;
  }

  .footer {
    font-size: x-small;
    color: grey;
    padding: 0.5rem;
  }

  .success {
    font-size: large;
    background-color: green;
    color: white;
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }

  .success p {
    width: 95%;
  }
}
